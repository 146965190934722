import React from "react";
import DesktopMenu from "./component/DesktopMenu";
import MobileMenu from "./component/MobileMenu";
import { Container } from "@mui/material";

const LockerMenu = (props) => {
  const menuList = [
    // You can pass 4 perameters for menu title, link, target and customClass
    {
      title: "Home",
      link: "#home",
    },
    {
      title: "Tokenomics",
      link: "#tokenomics",
    },
    {
      title: "Community",
      link: "#community",
    },
  ];
  return (
      <Container>
        <Container>
          <DesktopMenu menuList={menuList} />
          <MobileMenu menuList={menuList} />
        </Container>
      </Container>
  );
};

export default LockerMenu;
