import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { CustomGrid, DataImage, SectionHeading } from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import { MenuLink } from "../../LockerMenu/styles";
import x from "../../../../assets/images/x.png";
import tg from "../../../../assets/images/tg.png";
import Logo from "../../../../assets/images/logo.png";
import AOS from "aos";
import "aos/dist/aos.css";

const ContinerWraper = styled(Container)`
  background: linear-gradient(to top, #2a47ff, #1c30ac, #132178, #000000 50%);
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  width: 100%;
  display: grid;
  place-items: center;
  padding: 20px 50px !important;
  z-index: 1;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;
const CustomGridEx = styled(CustomGrid)`
  border-top: 1px solid white;
`;
const LastSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      offset: 200,
    });
  }, []);

  useEffect(() => {
    AOS.refresh();
  });
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="community">
        <InnerContinerWraper>
          <Grid container sx={{ padding: "70px 0px" }}>
            <CustomGrid fd="column" item xs={12}>
              <SectionHeading fm="PoppinsSB" data-aos="fade-up">
                🤖Join The Community🤖
              </SectionHeading>
              <Paragraph
                sx={{ maxWidth: "500px" }}
                p="20px 0px"
                fs="30px"
                data-aos="fade-up"
              >
                Join the Grok movement and be on the winning side of history!
              </Paragraph>
              <CustomGrid item xs={12}>
                <MenuLink
                  href="https://t.me/bgrokfinance"
                  target="blank"
                  data-aos="zoom-in"
                >
                  <DataImage mw="50px" src={tg} />
                </MenuLink>
                <MenuLink
                  href="https://twitter.com/bgrokfinance"
                  target="blank"
                  data-aos="zoom-in"
                >
                  <DataImage mw="50px" src={x} />
                </MenuLink>
              </CustomGrid>
            </CustomGrid>
          </Grid>
          <Grid container>
            <CustomGrid ai="left" jc="left" item xs={12} md={8} fd="column">
              <DataImage mw="250px" src={Logo} />
              <Paragraph ta="left" fs="16px" p="20px 0px" maxWidth="300px">
                Baby Grok inherits its prowess from XAI's advanced AI models,
                including Elon Musk's Grok.
              </Paragraph>
            </CustomGrid>
            <CustomGrid item xs={6} md={2} fd="column" js="left" ai="left">
              <Paragraph ta="left" fm="poppinsSB">
                Quick Links
              </Paragraph>
              <MenuLink p599="2px 0px" p="5px 0px" href="#home">
                Home
              </MenuLink>
              <MenuLink p599="2px 0px" p="5px 0px" href="#tokenomics">
                Tokenomics
              </MenuLink>
              <MenuLink p599="2px 0px" p="5px 0px" href="#community">
                Community
              </MenuLink>
            </CustomGrid>
            <CustomGrid item xs={6} md={2} fd="column" js="left" ai="left">
              <Paragraph p="10px 0px" ta="left" fm="poppinsSB">
                Find us on
              </Paragraph>
              <MenuLink
                p599="2px 0px"
                td="underline"
                target="blank"
                href="https://twitter.com/bgrokfinance"
              >
                X (Twitter)
              </MenuLink>
              <MenuLink
                p599="2px 0px"
                td="underline"
                target="blank"
                href="https://t.me/bgrokfinance"
              >
                Telegram
              </MenuLink>
              <MenuLink
                p599="2px 0px"
                td="underline"
                target="blank"
                href="https://etherscan.io/token/0xb1d9a6a08c2e16d5481e6eb395ce63a5a84a7602"
              >
                Etherscan
              </MenuLink>
              <MenuLink
                p599="2px 0px"
                td="underline"
                target="blank"
                href="https://app.uniswap.org/#/swap?outputCurrency=0xb1D9A6a08C2e16D5481E6eB395Ce63A5A84a7602&chain=ethereum"
              >
                Uniswap
              </MenuLink>
              <MenuLink
                p599="2px 0px"
                td="underline"
                target="blank"
                href="https://www.dextools.io/app/en/ether/pair-explorer/0xb1D9A6a08C2e16D5481E6eB395Ce63A5A84a7602"
              >
                Dextools
              </MenuLink>
            </CustomGrid>
            <CustomGridEx item xs={12}>
              <Paragraph p="15px 0px 0px" fs="12px" fm="poppinsSB">
                © 2023 BabyGrok Token.
              </Paragraph>
            </CustomGridEx>
          </Grid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};
export default LastSection;
