import React from "react";
import logo from "../../../../assets/images/logo.png";
import x from "../../../../assets/images/x.png";
import tg from "../../../../assets/images/tg.png";
import { DesktopMainMenu, MenuLink } from "../styles";
import { DataImage } from "../../../Styles/style";
const DesktopMenu = (props) => {
  return (
    <DesktopMainMenu maxWidth="xl">
      <MenuLink href="/" p="0px">
        <DataImage src={logo} mw="220px" alt="Logo" />
      </MenuLink>
      <div style={{ display: "flex", alignItems: "center" }}>
        {props.menuList.map((value, i) => (
          <MenuLink
            key={i}
            href={value.link}
            target={value.target}
            className={value.customClass}
          >
            {value.title}
          </MenuLink>
        ))}
        <div style={{ display:"flex"}}>
          <MenuLink href="https://t.me/bgrokfinance" target="blank">
            <DataImage mw="30px" src={tg} />
          </MenuLink>
          <MenuLink href="https://twitter.com/bgrokfinance" target="blank">
            <DataImage mw="30px" src={x} />
          </MenuLink>
        </div>
      </div>
    </DesktopMainMenu>
  );
};

export default DesktopMenu;
