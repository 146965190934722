import React from "react";
import { Container, Grid, Link } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage } from "../../../Styles/style";
import backgroundImage from "../../../../assets/images/aboutBg.png";
import AboutBaby from "../../../../assets/images/aboutbaby.png";
import etherscan from "../../../../assets/images/etherscan.png";
import dex from "../../../../assets/images/dex.png";
import uniswap from "../../../../assets/images/uniswap.png";
import tg from "../../../../assets/images/tg.png";
import x from "../../../../assets/images/x.png";
import { MenuLink } from "../../LockerMenu/styles";
import BuyButton from "../../../../assets/images/BuyButton.png";
import ViewButton from "../../../../assets/images/ViewButton.png";

const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0 !important;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  display: grid;
  place-items: center;
  padding: 20px 50px !important;
  z-index: 1;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;

const AboutSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="about">
        <InnerContinerWraper>
          <CustomGrid container jc="space-between">
            <Grid container item xs={12} g="20px" jc="start">
              <CustomGrid item xs={12} sm={6} fd="column">
                <div style={{ display: "flex", gap: "20px" }}>
                  <Link
                    data-aos="fade-up"
                    href="https://etherscan.io/token/0xb1d9a6a08c2e16d5481e6eb395ce63a5a84a7602"
                    target="blank"
                  >
                    <DataImage mw="50px" src={etherscan} />
                  </Link>
                  <Link
                    data-aos="fade-up"
                    href="https://www.dextools.io/app/en/ether/pair-explorer/0xb1D9A6a08C2e16D5481E6eB395Ce63A5A84a7602"
                    target="blank"
                  >
                    <DataImage mw="50px" src={dex} />
                  </Link>
                  <Link
                    data-aos="fade-up"
                    href="https://app.uniswap.org/#/swap?outputCurrency=0xb1D9A6a08C2e16D5481E6eB395Ce63A5A84a7602&chain=ethereum"
                    target="blank"
                  >
                    <DataImage mw="50px" src={uniswap} />
                  </Link>
                  <Link
                    data-aos="fade-up"
                    href="https://t.me/bgrokfinance"
                    target="blank"
                  >
                    <DataImage mw="50px" src={tg} />
                  </Link>
                  <Link
                    data-aos="fade-up"
                    href="https://twitter.com/bgrokfinance"
                    target="blank"
                  >
                    <DataImage mw="50px" src={x} />
                  </Link>
                </div>
                <div
                  style={{ display: "flex", gap: "10px", padding: "20px 0px" }}
                >
                  <MenuLink
                    href="https://app.uniswap.org/#/swap?outputCurrency=0xb1D9A6a08C2e16D5481E6eB395Ce63A5A84a7602&chain=ethereum "
                    target="blank"
                  >
                    <DataImage data-aos="fade-in" mw="150px" src={BuyButton} />
                  </MenuLink>
                  <MenuLink
                    href="https://www.dextools.io/app/en/ether/pair-explorer/0xb1D9A6a08C2e16D5481E6eB395Ce63A5A84a7602"
                    target="blank"
                  >
                    <DataImage data-aos="fade-in" mw="150px" src={ViewButton} />
                  </MenuLink>
                </div>
              </CustomGrid>
              <CustomGrid js="center" ai="end" item xs={12} sm={6}>
                <DataImage data-aos="zoom-in" mw="80%" src={AboutBaby} />
              </CustomGrid>
            </Grid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default AboutSection;
