import { Box, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import TokenBackground from "../../../../assets/images/tokenBg.png";
import styled from "@emotion/styled";
import TokenBaby from "../../../../assets/images/tokenBaby.png";
import { CustomGrid, DataImage } from "../../../Styles/style";
import TokenDetail1 from "../../../../assets/images/tokenbgd1.png";
import TokenDetail2 from "../../../../assets/images/tokenbgd2.png";
import Paragraph from "../../Paragraph";
import TokenLine from "../../../../assets/images/tokenline.png";
import { MenuLink } from "../../LockerMenu/styles";
import CopyButton from "../../../../assets/images/copyButton.png";

const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  background-image: url(${TokenBackground});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0 !important;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  padding: 0% !important;
  margin: 0% !important;
  position: relative;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 20px !important;
    background-image: none;
  }
`;
const BoxBackground = styled(Box)`
  background-image: ${(props) => (props.bi ? props.bi : "")};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  max-width: 70%;
  padding: 40px 30px;
  min-height: 40%;
  position: relative;
  @media (min-width: 599px) and (max-width: 1000px) {
    max-width: 100%;
  }
  @media (max-width: 599px) {
    max-width: 100%;
  }
`;
const MainDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  @media (max-width: 599px) {
    flex-direction: column;
  }
`;
const TokenomicSection = () => {
  const [showToast, setShowToast] = useState(false);
  const addressToCopy = "0xb1D9A6a08C2e16D5481E6eB395Ce63A5A84a7602";
  const handleCopy = () => {
    navigator.clipboard.writeText(addressToCopy);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 2000);
  };
  return (
    <ContinerWraper maxWidth="xxl" id="tokenomics">
      <MainDiv>
        <InnerContinerWraper>
          <Grid container sx={{ margin: "0 !important" }}>
            <CustomGrid item xs={12} md={8}>
              <DataImage data-aos="zoom-in" src={TokenBaby} />
            </CustomGrid>
          </Grid>
        </InnerContinerWraper>
        <InnerContinerWraper>
          <CustomGrid fd="column" item xs={12} md={6} ai="end">
            <BoxBackground bi={`url(${TokenDetail1})`}>
              <Paragraph
                data-aos="fade-up"
                ta="left"
                fs="20px"
                fm="PoppinsB"
                p="5px"
              >
                Tokenomics
              </Paragraph>
              <DataImage src={TokenLine} />
              <Paragraph
                data-aos="fade-up"
                ta="left"
                fs="30px"
                fm="PoppinsB"
                p="5px"
              >
                10,000,000,000
              </Paragraph>
              <Paragraph
                data-aos="fade-up"
                ta="left"
                fs="20px"
                fm="PoppinsB"
                p="5px"
              >
                Supply
              </Paragraph>
            </BoxBackground>
            <BoxBackground bi={`url(${TokenDetail2})`}>
              {showToast && (
                <div
                  style={{
                    width: "300px",
                    height: "40px",
                    borderRadius: "0px",
                    position: "absolute",
                    top: "0",
                    left: "50%",
                    transform: "translate(-50%,0%)",
                    display: "grid",
                    placeItems: "center",
                    background: "#000",
                    color: "#FEC82A",
                    zIndex: "1",
                  }}
                >
                  Contract Address Copied
                </div>
              )}
              <Grid container>
                <Grid item xs={6}>
                  <Paragraph
                    data-aos="fade-up"
                    ta="left"
                    fs="30px"
                    fm="PoppinsB"
                    p="5px"
                  >
                    2/3🚀
                  </Paragraph>
                  <Paragraph
                    data-aos="fade-up"
                    ta="left"
                    fs="18px"
                    fm="PoppinsSB"
                    p="0px 5px"
                  >
                    Buy/Sell Tax
                  </Paragraph>
                </Grid>
                <Grid item xs={6}>
                  <Paragraph
                    data-aos="fade-up"
                    ta="left"
                    fs="30px"
                    fm="PoppinsB"
                    p="5px"
                  >
                    Locked
                  </Paragraph>
                  <Paragraph
                    data-aos="fade-up"
                    ta="left"
                    fs="18px"
                    fm="PoppinsSB"
                    p="0px 5px"
                  >
                    Liquidity
                  </Paragraph>
                </Grid>
              </Grid>
              <MenuLink onClick={handleCopy} p="20px 0px 20px">
                <DataImage data-aos="fade-in" mw="50%" src={CopyButton} />
              </MenuLink>
            </BoxBackground>
          </CustomGrid>
        </InnerContinerWraper>
      </MainDiv>
    </ContinerWraper>
  );
};
export default TokenomicSection;
