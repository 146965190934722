import React, { useEffect } from "react";
import { Container, Grid, Link } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage, SectionHeading } from "../../../Styles/style";
import backgroundImage from "../../../../assets/images/backgroundImage.png";
import LockerMenu from "../../LockerMenu";
import Paragraph from "../../Paragraph";
import HeroImage1 from '../../../../assets/images/heroImage1.png';
import HeroImage2 from '../../../../assets/images/heroImage2.png';
import HeroArrow from '../../../../assets/images/heroarrowbutton.png';
import { MenuLink } from "../../LockerMenu/styles";
import HeroBottom from '../../../../assets/images/herobottom.png';
import AOS from "aos";
import "aos/dist/aos.css";

const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  background-image: url(${HeroBottom}), url(${backgroundImage});
  background-position: bottom, center;
  background-repeat: no-repeat;
  background-size: bottom, cover;
  padding: 0 !important;
  position: relative;
  z-index: 0;
  ::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to top,
      #010117,
      rgba(0, 0, 0, 0)
    );
    z-index: -1;
  }
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
display: flex;
    align-items: center;  min-height: calc(100svh - 70px);
  width: 100%;
  padding: 0 50px !important;
  z-index: 1;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 0 20px !important;
    background-image: none;
  }
`;
const MenuLinkEx = styled(MenuLink)`
  display: flex;
  justify-content: flex-end;
`
const GridContainerStyle  = styled(Grid)`
  background-color: #010117;
  padding: 10px;
`
const HeroSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      offset: 200,
    });
  }, []);

  useEffect(() => {
    AOS.refresh();
  });
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="home">
        <LockerMenu />
        <InnerContinerWraper>
          <CustomGrid container p="40px 0"  ai="start" jc="start">
              <Grid item xs={12} sx={{display: 'flex', justifyContent: "center", alignItems: 'center', textAlign: 'center'}}>
                <DataImage data-aos ='fade-up' data-aos-duration='2000' mw='100%' src={HeroImage1}/>
              </Grid>
              <GridContainerStyle container>
                <Grid item xs={12} md={5}>
                  <SectionHeading fs='25px' ta='left' data-aos ='fade-up'>
                    Are you ready for the new world order?
                  </SectionHeading>
                  <Paragraph
                    p='20px 0px'
                    ta='left'
                    fs='16px'
                    data-aos ='fade-up'
                  >
                    Baby Grok is looking to do its own thing. 
                    He wants to take over the crypto space using 
                    his superior intellect. Users can reap the benefits 
                    of Baby Grok's blockchain prowess by investing in 
                    Baby Grok's latest project!
                  </Paragraph>
                </Grid>
                <Grid item xs={12} md={7} sx={{textAlign: 'end'}}>
                  <DataImage data-aos='zoom-in' mw='75%' mw599='100%' src={HeroImage2}/>
                  <MenuLinkEx p='20px 0px'>
                    <a href='#about' style={{width:'700px'}}><DataImage data-aos='zoom-in' mw='5%' mw599='15%' src={HeroArrow}/></a>
                  </MenuLinkEx>
                </Grid>
              </GridContainerStyle>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default HeroSection;
